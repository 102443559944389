.link {
  display: flex;
  align-items: center;
  gap: 8px;
  outline: none;
}

.link.hover .image {
  opacity: 0;
  visibility: hidden;
}

.link.hover .imageHover {
  opacity: 1;
  visibility: visible;
}

.imageWrap,
.imageText {
  flex-shrink: 0;
  line-height: 0;
}

.imageWrap {
  position: relative;
  height: 26px;
  width: 26px;
}

.image,
.imageHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageHover svg {
  width: 100%;
  height: 100%;
}

.imageHover {
  opacity: 0;
  visibility: hidden;
}

.imageText {
  width: 70px;
  height: 14px;
}

.button {
  min-width: 211px;
  padding: 12px 16px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-a);
}

.button:focus {
  outline: none;
}

@media (max-width: 575px) {
  .link {
    gap: 10px;
  }

  .button {
    min-width: 160px;
    padding: 10px 12px;
  }
}
