.links {
  display: flex;
  background: var(--color-white-primary);
  gap: 25px;
}

.link {
  display: flex;
  align-items: center;
  padding: 17.5px 0;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--color-black-primary);
  gap: 4px;
}

.link.active {
  padding-bottom: 14.5px;
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
}

.link:hover,
.link:focus {
  color: var(--primary-color);
}

.linkArrow {
  line-height: 0;
  transition: transform var(--transition);
}

.link.open .linkArrow {
  transform: scaleY(-1);
}

.linkArrow svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 1024px) {
  .links {
    gap: 12px;
  }
}

@media (max-width: 767px) {
  .links {
    display: none;
  }
}
