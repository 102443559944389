.root {
  display: flex;
  gap: 24px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.28;
  color: #B3BBBE;
  text-transform: uppercase;
  gap: 8px;
}

.title::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #E8ECED;
}

.columnPopular {
  flex-shrink: 0;
  width: 30%;
}

.columnSolutions {
  flex-shrink: 0;
  width: 28%;
}


.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.category {
  display: flex;
  align-items: center;
  min-height: 42px;
  gap: 8px;
}

.category:hover,
.category:focus-visible {
  color: var(--primary-color);
}

.categoryIcon {
  flex-shrink: 0;
}

.categoryName {
  font-size: 14px;
  line-height: 1.28;
}

.categoryAll {
  color: var(--primary-color);
}

.categoryArrow {
  line-height: 0;
}

.categoryArrow svg {
  width: 12px;
  height: 12px;
}


.solution {
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.28;
  gap: 8px;
}

.solution:not(:last-child) {
  margin-bottom: 8px;
}

.solution:hover,
.solution:focus-visible {
  color: var(--primary-color);
}


.articles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.article {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.articleImage {
  border-radius: var(--border-radius-s);
}

.articleName {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.articleDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(14px * 3);
  font-size: 12px;
  line-height: 14px;
  color: #8A8D8F;
}

.articleLink {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
}

.articleArrow {
  line-height: 0;
}

.articleArrow svg {
  width: 12px;
  height: 12px;
}


.template {
  display: flex;
  align-items: center;
  gap: 12px;
}

.template:not(:last-child) {
  margin-bottom: 16px;
}

.templateName {
  display: block;
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.templateLink {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

@media (max-width: 1280px) {
  .articleImage {
    width: 128px;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-direction: column;
    gap: 16px;
  }

  .column {
    width: 100%;
  }

  .title {
    margin-bottom: 16px;
    color: #8A8D8F;
  }

  .articles {
    grid-template-columns: 1fr;
  }

  .articleImage {
    display: none;
  }

  .title::after {
    background: #DEE2E6;
  }

  .categories {
    grid-template-columns: 1fr;
  }
}
