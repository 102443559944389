.root {
  position: fixed;
  bottom: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  max-width: calc(100% - 48px);
  padding: 24px;
  background: #4B4E4F;
  color: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  font-size: 16px;
  gap: 24px;
  z-index: 1000;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-s);
}

@media (max-width: 575px) {
  .root {
    inset: auto 0 0;
    max-width: 100%;
    flex-direction: column;
    border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
    gap: 24px;
  }

  .button {
    width: 100%;
  }
}
