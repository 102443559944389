.items {
  margin-left: calc(var(--container-padding) * -1);
  margin-right: calc(var(--container-padding) * -1);
}

.item {
  border-bottom: 1px solid var(--grey-3);
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px var(--container-padding);
  font-weight: 600;
  font-size: 16px;
  color: #4B4E4F;
  gap: 10px;
}

.link.active,
.link.open {
  color: var(--primary-color);
}

.linkArrow {
  line-height: 0;
  transition: transform var(--transition);
}

.link.open .linkArrow {
  transform: scaleY(-1);
}

.linkArrow svg {
  width: 20px;
  height: 20px;
}

.children {
  padding: 16px 16px 16px 32px;
  background: var(--body-bg);
}

@media (max-width: 1024px) {
  .links {
    gap: 12px;
  }
}

@media (max-width: 767px) {
  .links {
    display: none;
  }
}
