.root {
  position: relative;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-white-primary);
  background: #2D352C;
  box-shadow: 0px 2px 10px 0px #0000000D;
  z-index: 31;
}

.root a,
.root button {
  color: var(--color-white-primary);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.links {
  display: flex;
  gap: 16px;
}

.link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.icon {
  line-height: 0;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.text {
  display: flex;
  gap: 16px;
}

.arrow {
  width: 11px;
  height: 11px;
}

.arrow svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .root {
    z-index: 29;
  }

  .container {
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .links {
    display: none;
  }
}
