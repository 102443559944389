.rc-slider {
  height: 20px;
  margin-bottom: 16px;
  padding: 4px 0;
}

.rc-slider-dot {
  display: none;
}

.rc-slider-step,
.rc-slider-track,
.rc-slider-rail {
  height: 6px;
}

.rc-slider-rail {
  background: var(--grey-6);
  border-radius: 10px;
}

.rc-slider-track {
  background: var(--primary-color);
}

.rc-slider-handle {
  margin-top: -5px;
  width: 16px;
  height: 16px;
  background: var(--primary-color);
  border: none;
  box-shadow: none;
  opacity: 1;
}

.rc-slider-handle.rc-slider-handle.rc-slider-handle-dragging {
  border: none;
  box-shadow: none;
}

.rc-slider-mark {
  top: 20px;
}

.rc-slider-mark-text {
  color: var(--grey-2);
  white-space: nowrap;
}

.rc-slider-mark-text:first-child {
  transform: none !important;
}

.rc-slider-mark-text:last-child {
  transform: translateX(-100%) !important;
}
