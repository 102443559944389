.root {
  padding-bottom: 1px;
  background: var(--color-white-primary);
}

.root.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: 30;
}

.root::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--color-white-primary);
  border-bottom: 1px solid #DEE2E6;
  z-index: 0;
}

.root.simple {
  position: relative;
  width: 100%;
  padding: 15px 0 16px;
  background: none;
  box-shadow: none;
}

.root.simple .container {
  justify-content: center;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.simple .logo {
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin-right: 25px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 10px;
}

.button {
  min-height: 34px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: 8px;
}

.signIn {
  padding-left: 12px;
  box-shadow: inset 0 0 0 1px var(--grey-3);
  background: var(--color-white-primary);
  gap: 8px;
}

.signInIcon {
  width: 18px;
  height: 18px;
}

.navMobile {
  flex-grow: 1;
  margin-right: 10px;
}

.phoneLink {
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.25;
  color: #4B4E4F;
  border-bottom: 2px solid #79BE70;
}

@media (max-width: 1024px) {
  .root {
    padding-top: 6px;
    padding-bottom: 7px;
  }

  .logo {
    margin-right: 10px;
  }

  .buttonPhone {
    color: var(--color-black-primary);
  }
}
