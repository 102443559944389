.menuButton {
  padding: 6px;
  line-height: 0;
  color: var(--color-black-primary);
}

.closeButton {
  line-height: 0;
  margin-right: -10px;
  padding: 10px;
  color: var(--color-black-primary);
}

.closeButton svg {
  width: 20px;
  height: 20px;
}

.overlay {
  display: none;
}

.overlay.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 99;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 352px;
  display: flex;
  flex-direction: column;
  background: var(--color-white-primary);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  transform: translateX(110%);
  transition: transform var(--transition);
  z-index: 100;
}

.menu.open {
  transform: translateX(0);
}

.head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  color: var(--grey-3);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-s);
  gap: 8px;
}

.signIn {
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.buttonsBottom {
  position: sticky;
  inset: auto 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px var(--container-padding);
  border-top: 1px solid var(--grey-3);
  background: var(--color-white-primary);
  gap: 8px;
}

.buttonsRow {
  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
}

.squareButton {
  min-height: 34px;
  padding: 6px 8px;
  box-shadow: inset 0 0 0 1px var(--grey-3);
  border-radius: var(--border-radius-s);
}

.squareButtonIcon {
  width: 18px;
  height: 18px;
}

.link {
  display: flex;
  align-items: center;
  padding: 16px 0;
  font-size: 16px;
  gap: 8px;
}

.icon {
  line-height: 0;
}

.icon svg {
  width: 20px;
  height: 20px;
}

.phoneLink {
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.25;
  color: #4B4E4F;
  border-bottom: 2px solid #79BE70;
}

@media (max-width: 575px) {
  .menuButton {
    padding: 6px 0;
  }

  .menu {
    left: 0;
    width: 100%;
  }

  .head {
    padding: 5px var(--container-padding);
  }

  .content {
    padding: 5px 15px 22px;
  }

  .title {
    padding-top: 0;
  }
}
