.root {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  font-size: 0.875rem;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-100%);
  transition: all 0.3s ease-in;
  z-index: -1;
}

.open {
  transform: translateY(0);
  visibility: visible;
  pointer-events: all;
}

.container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  background-color: var(--color-white-primary);
  box-shadow: 0 4px 4px -4px #0C0C0D0D, 0 16px 32px -4px #0C0C0D1A;
  border-bottom: 1px solid #DEE2E6;
  border-bottom-left-radius: var(--border-radius-m);
  border-bottom-right-radius: var(--border-radius-m);
  overflow: hidden;
}

.content {
  padding: 24px 32px 48px;
}

.bottom {
  display: flex;
  padding: 16px 32px;
  background: #F9FAFA;
  border-top: 1px solid #DEE2E6;
  gap: 8px;
}

.link {
  padding: 7.5px 14px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  color: #4B4E4F;
  cursor: pointer;
}

.divider {
  height: 34px;
  border-right: 1px solid #DEE2E6;
}

.connect {
  margin-left: auto;
}

.appModal {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.appLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  outline: none;
  gap: 12px;
}

.appQr {
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
}
