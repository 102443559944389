.root {
  padding-top: 40px;
  padding-bottom: 20px;
  background: var(--color-white-primary);
}

.container {
  display: flex;
  gap: 50px;
}

.root.simple {
  background: none;
}

.root.simple .createWithForma {
  margin-left: auto;
  margin-right: auto;
}

.simple .logo {
  width: 32px;
  height: 32px;
}

.title {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
}

.item:not(:last-child) {
  margin-bottom: 16px;
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--grey-0);
  font-size: 14px;
  word-wrap: break-word;
}

.link:hover,
.link:focus {
  color: var(--color-black-primary);
}

.cursorDefault {
  cursor: default;
}

.linkIcon {
  flex-shrink: 0;
  margin-right: 10px;
  color: var(--color-black-primary);
  line-height: 0;
}

.description {
  max-width: 332px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.28;
  color: var(--grey-0);
}

.company {
  margin-right: auto;
}

.column {
  min-width: 120px;
  max-width: 150px;
}

.createWithForma {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--headings-font);
  font-size: 18px;
  font-weight: 500;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid var(--grey-3);
}

.copyrightText {
  font-size: 12px;
  line-height: 1.16;
  color: var(--grey-0);
}

.contactsLink {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: var(--grey-0);
  font-size: 14px;
  gap: 8px;
}

.contactsLinkIcon {
  line-height: 0;
}

.copyrightButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.copyrightButton {
  flex-shrink: 0;
  min-height: 34px;
  padding: 8px;
  border-radius: var(--border-radius-xs);
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.copyrightButtonIcon {
  width: 18px;
  height: 18px;
}

.appLink {
  flex-shrink: 0;
  line-height: 0;
}

.langSwitch>button {
  padding: 8.5px 0;
}

@media (max-width: 1280px) {
  .root {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .root {
    padding-bottom: 16px;
  }

  .container {
    gap: 15px;
  }

  .column {
    width: 100px;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .column {
    width: 100%;
  }

  .title {
    font-size: 16px;
  }

  .createWithForma {
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }

  .copyright {
    flex-direction: column-reverse;
    padding-top: 32px;
    margin-top: 32px;
    gap: 24px;
  }

  .copyrightButtons {
    max-width: 300px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 16px;
  }

  .appLink {
    order: 1;
  }
}

@media (max-width: 575px) {
  .root {
    padding: 50px 0;
  }

  .link {
    color: var(--grey-1);
  }

  .copyright {}
}
